import React from "react";

const Footer = () => {
  return (
    <footer>
      <span>
        <a href="https://nodejs.edu.vn">nodejs.edu.vn</a> - Copyright 2024
      </span>
    </footer>
  );
};

export default Footer;
